import React, {Component} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';
import {Expo} from 'gsap/TweenMax';
import {tabbable} from 'utils/accessibility'

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import ImageSwap from 'components/ImageSwap';
import FullScreenContainer from 'components/FullScreenContainer';
import {getPercentageBetweenBounds} from 'utils';

import scene from 'components/scene'
import {
  placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow, makeElementTabbable, unmakeElementTabbable
} from 'utils/animation';

import './chapter-3-scene-10.scss';

const chartSwapStart = 0.6;
const chartSwapEnd = 1;

const C3S10 = class extends Component {
  render() {
    const {
      chartA,
      chartB,
      chartC,
    } = this.props.data;
    const {position} = this.props;
    const chartSwapProgress = getPercentageBetweenBounds(
      position,
      chartSwapStart,
      chartSwapEnd
    );

    return (
      <FullScreenContainer className="chapter-3-scene-10">
        <div className="chapter-3-scene-10__backgrounds">
          <div ref="chart1" className="chapter-3-scene-10__map">
            <Image image={chartA} className="chapter-3-scene-10__map__image" alt="map of Alabama's majority african american counties" />
          </div>
          <div ref="chart2" className="chapter-3-scene-10__backgrounds__charts">
            <ImageSwap
              image={chartB}
              image2={chartC}
              position={chartSwapProgress}
              alt="chart showing democratic deficit in Alabama counties"
            />
          </div>
        </div>
        <div className="chapter-3-scene-10__text">
          <p ref="paragraph1" className="alt">
            Like the Mississippi Delta in Freedom Summer, Alabama black belt
            counties like Dallas, Perry, and Lowndes, were majority African
            American.
          </p>
          <h3 {...tabbable} ref="paragraph2">
            All but a handful of registered voters were white.<br />
            <span className="highlight">The democratic deficit was stark</span>.
          </h3>
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    chart1,
    chart2,
    paragraph1,
    paragraph2,
  } = context.refs;

  TweenMax.set(chart1, {...showElemWithAria});
  TweenMax.set(chart2, {...hideElementWithAria});
  TweenMax.set([paragraph1,paragraph2], {...hideFromBelow});
  TweenMax.set([paragraph1], {...makeElementTabbable})
  TweenMax.set(paragraph2, {...hideElementWithAria, y: 50});
  timeline.to(paragraph1, 1.5, {...revealFromBelow, ...easeOut});
  timeline.markers.createTimelineMarker();
  timeline.to(chart2, 1, {...showElemWithAria});
  timeline.to(paragraph1, 0, {...unmakeElementTabbable});
  timeline.to(paragraph2, 1.5, {...revealFromBelow, ...easeOut}, "-=1");
  placeholder(timeline, 2.5)
  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    chartA: file(
      relativePath: { eq: "chapter-3/scene-10/images/ch3-info-alabama_county_voters-a.jpg" }
    ) {
      ...largeImage
    }
    chartB: file(
      relativePath: { eq: "chapter-3/scene-10/images/ch3-info-alabama_county_voters-b.jpg" }
    ) {
      ...largeImage
    }
    chartC: file(
      relativePath: { eq: "chapter-3/scene-10/images/ch3-info-alabama_county_voters-c.jpg" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S10, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
