import React, {Component} from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import SplitView from 'templates/SplitView';
import scene from 'components/scene';
import {tabbable} from 'utils/accessibility'

import {
  placeholder, hideElementWithAria, showElemWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';

import './chapter-3-scene-6.scss';

const C3S6 = class extends Component {
  render() {
    const {
      fannieLouBg,
      fannieLou,
    } = this.props.data;

    return (
      <div ref="scene6">
        <FullScreenContainer className="chapter-3-scene-6">
          <SplitView
            fullWidthPanel={
              <Image
                className="chapter-3-scene-6__background"
                image={fannieLouBg}
                alt="Fannie Lou Hamer background"
              />
            }
            leftPanel={
              <div {...tabbable} className="chapter-3-scene-6__text">
                <p ref="p1">
                  At the 1964 Democratic National Convention (DNC), Fannie Lou
                  Hamer led the efforts of the MFDP to publicize the denial of
                  voting rights in the state.{' '}
                </p>
                <p ref="p2" className="alt">
                  She gave an electrifying address to the convention
                  highlighting the violence that she and others faced in seeking
                  the vote.
                </p>
              </div>
            }
            rightPanel={
              <Image
                className="chapter-3-scene-6__fannie"
                image={fannieLou}
                showAria
                alt="Fannie Lou Hamer of Sunflower County, Miss., testifies before the Senate Subcommittee on Poverty in Miss., April 10, 1967. In background is another witness, Rev. J.C. Killingsworth. (AP Photo/Jack Thornell)"
              />
            }
          />
        </FullScreenContainer>
      </div>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {p1, p2, scene6} = context.refs;
  // console.log(revealFromBelow, hideFromBelow)
  TweenMax.set([p1,p2], {...hideFromBelow})
  timeline.to(p1, 1.5, {...revealFromBelow, ...easeOut});
  timeline.to(p2, 1.5, {...revealFromBelow, ...easeOut}, "-=1.25");


};

const query = graphql`
  query {
    fannieLouBg: file(
      relativePath: { eq: "chapter-3/scene-6/images/ch-3-image-1968-dnc@3x.jpg" }
    ) {
      ...largeImage
    }
    fannieLou: file(
      relativePath: { eq: "chapter-3/scene-6/images/ch-3-image-fannie-lou-dnc@3x.png" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S6, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
