import React from 'react';
import {TweenMax} from 'gsap';
import {Expo} from 'gsap/TweenMax';
import {useStaticQuery, graphql} from 'gatsby';
import Modal from 'components/ChapterModal';
import {stickyPositions} from 'components/ModalContent';
import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import ScrollContainer from 'components/ScrollContainer';
import SplitView, {viewTypes} from 'templates/SplitView';
import {tabbable} from 'utils/accessibility'

import {
  placeholder,
  showElemWithAria,
  hideElementWithAria,
} from 'utils/animation';

import './chapter-3-scene-1.scss';

import scene from 'components/scene';

const C3S1 = class extends React.Component {
  render() {
    const {
      img1,
      img2,
      pencilA,
      literacyTest,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-1">
        <div
          ref={(ref) => (this.content = ref)}
          className="chapter-3-scene-1__content"
        >
          <SplitView
            viewType={viewTypes.ONE_THIRD}
            rightPanel={
              <div ref={(ref) => (this.image1 = ref)}>
                <Image
                  className="chapter-3-scene-1__image"
                  image={img1}
                  alt="voter and policeman"
                />
              </div>
            }
          />
          <SplitView
            viewType={viewTypes.ONE_THIRD}
            leftPanel={
              <div className="chapter-3-scene-1__copy">
                <div className="hidden-text" />
              </div>
            }
            rightPanel={
              <div>
                <Image
                  className="chapter-3-scene-1__image"
                  image={img2}
                  alt="literacy test page"
                />
                <div className="chapter-3-scene-1__pencil">
                  <div className="chapter-3-scene-1__pencil__piece section-a">
                    <Image image={pencilA} alt="pencil image fragment" />
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <ScrollContainer className="text-container">
          <div
            className="shifting-text"
            ref={(ref) => (this.shiftingText = ref)}
          >
            <p {...tabbable} className="part-1-text h3 short" ref={(ref) => (this.textPt1 = ref)}>
              There was, however,{' '}
              <span className="highlight">one major weakness</span> in the 1964
              Civil Rights Act:
            </p>
            <p {...tabbable} ref={(ref) => (this.votingRights = ref)} className="display">
              voting rights
            </p>
            <p {...tabbable} ref={(ref) => (this.secondP = ref)} className={`small h4 short`}>
              Title I of the act only required that voting rights procedures be applied equally to all races.
            </p>
            <p {...tabbable} className="small h4 short" ref={(ref) => (this.textPt2 = ref)}>
              <span className="highlight">It did not abolish</span> literacy
              tests or provide the tools to prevent local boards like Dallas
              County from{' '}
              <span className="highlight">
                discriminating against black voters
              </span>{' '}
              through the use of such tactics.
            </p>
            <div ref={(ref) => (this.modal = ref)} className="modal-section">
              <Modal
                className="chapter-3-scene-1-modal"
                stickyPosition={stickyPositions.NO_STICKY}
              >
                <div className="chapter-3-scene-1-modal__content">
                  <p className="louisiana__title">
                    Voter Literacy Test (c. 1965)
                  </p>
                  <div className="louisiana__letter">
                    <Image image={literacyTest} alt="image of a literacy test" showAria />
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </ScrollContainer>
      </FullScreenContainer>
    );
  }
};

const query = graphql`
  query {
    img1: file(
      relativePath: { eq: "chapter-3/scene-1/images/scene_1_img_1.jpg" }
    ) {
      ...largeImage
    }
    img2: file(
      relativePath: { eq: "chapter-3/scene-1/images/scene_1_img_2.jpg" }
    ) {
      ...largeImage
    }
    pencilA: file(
      relativePath: { eq: "chapter-3/scene-1/images/scene_1_pencil_1.png" }
    ) {
      ...mediumImage
    }
    literacyTest: file(
      relativePath: { eq: "chapter-3/scene-1/images/scene_1_literacy-test.png" }
    ) {
      ...smallImage
    }
  }
`;

const animation = ({context, timeline, easeOut}) => {
  const {
    image1,
    textPt1,
    textPt2,
    content,
    shiftingText,
    secondP,
    votingRights,
    modal,
  } = context;
  TweenMax.set([image1, textPt2, secondP, votingRights, modal], {
    ...hideElementWithAria,
  });
  TweenMax.set(textPt1, {...showElemWithAria});
  TweenMax.set([shiftingText, secondP, votingRights], {
    y: 50,
    ...hideElementWithAria,
  });

  // timeline.markers.createTimelineMarker();
  placeholder(timeline, 1);

  timeline.to(
    shiftingText,
    1.5,
    {y: 0, ...showElemWithAria, ...easeOut},
    '-=0.5'
  );

  // timeline.markers.createTimelineMarker();

  timeline.to(image1, 0.5, {...showElemWithAria});
  placeholder(timeline, 0.5);
  timeline.to(
    votingRights,
    1.5,
    {...showElemWithAria, y: 0, ...easeOut, onStart: () => textPt1.focus() },
    '-=0.25'
  );
  placeholder(timeline, 0.25);
  timeline.to(secondP, 1.5, {...showElemWithAria, y: 0, ...easeOut});

  timeline.markers.createTimelineMarker();

  timeline.to(content, 2, {y: '-100vh', ease: Expo.easeInOut});
  const {y: yPart1, height: heightPart1} = textPt1.getBoundingClientRect();
  // console.log(yPart1, heightPart1)
  const {y: votingRightsY} = votingRights.getBoundingClientRect();
  // console.log(votingRightsY)
  timeline.to(
    shiftingText,
    2,
    {y: -heightPart1, ease: Expo.easeInOut},
    '-=2'
  );

  timeline.to(textPt1, 0.5, {...hideElementWithAria}, '-=1');
  timeline.to(textPt2, 0.5, {...showElemWithAria}, '-=1');
  timeline.to(modal, 0.5, {...showElemWithAria}, '-=1');

  timeline.to({placeholder: 0}, 0.25, {placeholder: 1});
  timeline.markers.createTimelineMarker();
};

const SceneComponent = scene(timeline(C3S1, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
