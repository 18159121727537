export const Chapter3Qa = [
  {
    questionNumber: '3.01',
    question: 'Why wasn’t the 1964 Civil Rights Act enough to secure voting rights for African Americans?',
    answer: `It did not abolish literacy tests or provide the tools to prevent local boards from discriminating against black voters. It did not prevent racist violence or economic intimidation of black voters.`,
  },
  {
    questionNumber: '3.02',
    question: 'What were the successes of the Mississippi Freedom Democratic Party’s (MFDP) Freedom Summer in 1964?',
    answer: `Successes include: it showed that African American communities, allied with students, could begin to register and educate voting age citizens about their rights, even in Mississippi. The MFDP forced national Democrats, including President Johnson to focus on voting rights.`,
  },
  {
    questionNumber: '3.03',
    question: 'What problems did the MFDP face?',
    answer: `Problems include: still only a small percentage of MS’ black citizens were registered.  A fierce white backlash led to the murders of three MFDP student workers. President Johnson opposed the seating of the MFDP at the Democratic National Convention.`,
  },
  {
    questionNumber: '3.04',
    question: 'Why did Martin Luther King, Jr. come to Selma in January 1965?',
    answer: `Answers include: MLK had great moral authority due to his recent Nobel Peace Prize; the earlier SNCC and DVLC protests meant the community was strong enough to resist a white backlash; activists knew that Sheriff Jim Clark would provoke a violent confrontation against King and his allies that would focus global attention on Selma.`,
  },
];
