import React from 'react';
import {TweenMax} from 'gsap';
import {Expo} from 'gsap/TweenMax';
import {useStaticQuery, graphql} from 'gatsby';
import {tabbable} from 'utils/accessibility'

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import {
  placeholder, showElemWithAria, hideElementWithAria, createLightThemeOnStartSetter, makeElementTabbable, unmakeElementTabbable
} from 'utils/animation';

import scene from 'components/scene'
import './chapter-3-scene-4.scss';

const C3S4 = class extends React.Component {
  constructor(props) {
    super(props);

    const imageRefs = [
      'arrestImageB',
      'backlashImage2',
      'backlashImage3',
    ];

    imageRefs.map((name) => this[name] = React.createRef());
  }

  render() {
    const {
      arrestImageA,
      arrestImageB,
      backlashImage2,
      backlashImage3,
      votersImage,
      arrow,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-4">
        <div ref="voters" className="chapter-3-scene-4__voters">
          <div className="chapter-3-scene-4__voters__images">
            <div
              ref="votersImage"
              className="chapter-3-scene-4__voters__images__voter-turnout">
              <Image showAria image={votersImage} alt="Blacks line up in Greenwood, Miss., to register to vote on Freedom Day, July 16. By noon, just six had had been permitted to apply." />
            </div>
            <div
              ref="arrestImage"
              className="chapter-3-scene-4__voters__images__arrest">
              <div
                ref="arrestImageContainer"
                className="chapter-3-scene-4__voters__images__arrest__container">
                <Image image={arrestImageA} showAria alt="USA. 1964. Hattiesburg, Mississippi. Hattiesburg. Voter Registration and demonstration, Bob MOSES." />
                <div ref="arrestImageB">
                  <Image image={arrestImageB} alt="Bob Moses getting arrested photo" />
                </div>
              </div>
              <div ref='label' className="info-container">
                <p className="name">Bob Moses</p>
                <p className="title">- MFDP + SNCC</p>
                <Image className='arrow' image={arrow} alt="Arrow pointing to Bob Moses"/>
              </div>
            </div>
          </div>
          <div ref="votersText" className="chapter-3-scene-4__voters__text">
            <div ref="votersTextContainer">
              <h3 ref="votersTextTitle" className="uppercase">
                Their efforts succeeded in registering
              </h3>
              <p ref="votersTextCount" className="chapter-3-scene-4__voters__text__vote-number uppercase">
                1,200 <span>voters</span>
              </p>
            </div>
            <p
              ref="arrestText"
              className="chapter-3-scene-4__voters__text__arrested">
              but almost as many volunteers and locals were arrested.
            </p>
          </div>
        </div>
        <div ref="backlash" className="chapter-3-scene-4__backlash">
          <h3 {...tabbable} className="chapter-3-scene-4__backlash__headline uppercase">
            The white backlash was intense.
Civil rights workers were beaten and
            threatened with violence.
          </h3>
          <div className="chapter-3-scene-4__backlash__images">
            <div className="image-container" ref="backlashImage1">
              <Image
                className="chapter-3-scene-4__backlash__images__image image1"
                image={backlashImage3}
                showAria
                alt="Civil rights worker Monroe Sharp is among the 111 people, including 13 juveniles, who are arrested on Freedom Day, July 16."
              />
            </div>
            <div className="image-container" ref="backlashImage3">
              <Image
                className="chapter-3-scene-4__backlash__images__image image3"
                image={backlashImage2}
                showAria
                alt="Car burned by night riders (Klan) in Mileston."
              />
            </div>
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, sceneId, setTheme, easeOut, easeIn}) => {
  const {
    arrestImage,
    arrestImageB,
    votersText,
    votersTextContainer,
    arrestText,
    backlash,
    votersImage,
    votersTextTitle,
    arrestImageContainer,
    backlashImage1,
    backlashImage3,
    votersTextCount,
    label,
  } = context.refs;

  const setThemeLight = createLightThemeOnStartSetter(setTheme)

  TweenMax.set([label, arrestImage, arrestImageB, backlash], {...hideElementWithAria});
  TweenMax.set([votersText, arrestText], {y: 50, ...hideElementWithAria});

  TweenMax.set(votersTextTitle, {css: {color: 'black', opacity: 1}});
  TweenMax.set(votersTextCount, {...showElemWithAria});
  TweenMax.set(arrestImageContainer, {
    y: '-50%',
    x: '-50%',
    rotation: '0deg',
    scale: 1,
  });
  // TweenMax.set(votersImage, {...hideElementWithAria, y: 100});

  timeline.to(votersText, 1.5, {y: 0, ...showElemWithAria, ...easeOut});
  timeline.to(votersTextContainer, 0, { ...makeElementTabbable}, "-=1.5")

  timeline.markers.createTimelineMarker();

  timeline.to(arrestImage, 1, {...showElemWithAria, ...setThemeLight});
  timeline.to(votersImage, 1, {...hideElementWithAria}, '-=1');
  timeline.to(votersTextContainer, 0, {...unmakeElementTabbable}, '-=0.01')
  timeline.to(arrestText, 0, {...makeElementTabbable}, '-=0.01')

  timeline.to(
    votersTextTitle,
    1,
    {css: {color: 'white', opacity: 0.2}},
    '-=1'
  );
  timeline.to(votersTextCount, 1, {opacity: 0.2}, '-=1');
  timeline.to(label, 1, {...showElemWithAria}, '-=0.5');
  timeline.to(arrestText, 1, {y: 0, ...showElemWithAria,  ...easeOut}, '-=0.5');

  timeline.markers.createTimelineMarker();

  timeline.to(label, 1, {...hideElementWithAria});
  timeline.to(arrestImageContainer, 2, {
    rotation: '-15deg',
    scale: 0.75,
    x: '-55%',
    y: '-30%',
    ease: Expo.easeInOut
  }, "-=1");
  timeline.to(arrestImageB, 1, {...showElemWithAria}, '-=1');
  timeline.to(votersText, 1, {...hideElementWithAria}, '-=1');
  timeline.to(arrestImage, 1, {
    rotation: '-30deg',
    // scale: 0.75,
    x: '-100%',
    y: '-50%',
    ...easeIn,
  }, "-=1");
  timeline.to(backlash, 1, {...showElemWithAria});

  timeline.from(
    backlashImage3,
    2,
    {y: window.innerHeight, rotation: '5deg', ease: Expo.easeInOut},
    '-=1'
  );

  timeline.from(
    backlashImage1,
    2,
    {
      x: -window.innerWidth,
      y: -window.innerHeight * 0.25,
      rotation: '-90deg',
      ease: Expo.easeInOut
    },
    '-=1.75'
  );
  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    arrestImageA: file(
      relativePath: { eq: "chapter-3/scene-4/images/ch3-image-voter_arrest-a.jpg" }
    ) {
      ...largeImage
    }
    arrestImageB: file(
      relativePath: { eq: "chapter-3/scene-4/images/ch3-image-voter_arrest-b.jpg" }
    ) {
      ...largeImage
    }
    backlashImage2: file(
      relativePath: { eq: "chapter-3/scene-4/images/ch3-image-backlash-2.jpg" }
    ) {
      ...smallImage
    }
    backlashImage3: file(
      relativePath: { eq: "chapter-3/scene-4/images/ch3-image-backlash-3.jpg" }
    ) {
      ...smallImage
    }
    votersImage: file(
      relativePath: { eq: "chapter-3/scene-4/images/ch-3-image-1200-voters@2x.jpg" }
    ) {
      ...smallImage
    }
    arrow: file(
      relativePath: { eq: "chapter-3/scene-4/images/ch-3-arrow-bob-moses@2x.png" }
    ) {
      ...mediumImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S4, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
