import React from 'react';
import {TweenMax} from 'gsap';
import {useStaticQuery, graphql} from 'gatsby';
import {tabbable} from 'utils/accessibility'

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import SplitView from 'templates/SplitView';
import {
  placeholder, showElemWithAria, hideElementWithAria
} from 'utils/animation';
import scene from 'components/scene';


import './chapter-3-scene-8.scss';
import { addTab } from '../../../../utils/animation';

const C3S8 = class extends React.Component {
  constructor(props) {
    super(props);

    const imageRefs = [
      'kingImage1',
      'kingImage2',
      'kingImage3',
    ];

    imageRefs.map((name) => this[name] = React.createRef());
  }

  render() {
    const {
      map,
      mlkPulpit,
      mlkResting,
      mlkUnion,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-8">
        <SplitView
          fullWidthPanel={
            <div className="chapter-3-scene-8__images">
              <div className="chapter-3-scene-8__images__background">
                <Image image={map}
                  alt="map of mississippi and alabama"
                />
              </div>
              <div className="chapter-3-scene-8__images__photos">
                <div className="chapter-3-scene-8__images__photos__photo pulpit">
                  <div ref="kingImage1" role='img' aria-label="Civil rights leader Dr. Martin Luther King Jr. making a speech. (Photo by Grey Villet/The LIFE Picture Collection via Getty Images)">
                    <Image image={mlkPulpit}
                      alt="Civil rights leader Dr. Martin Luther King Jr. making a speech. (Photo by Grey Villet/The LIFE Picture Collection via Getty Images)"
                     />
                  </div>
                </div>
                <div className="chapter-3-scene-8__images__photos__photo resting">
                  <div ref="kingImage2" role='img' aria-label='23rd May 1961: American clergyman and civil rights campaigner Martin Luther King (1929 - 1968) (centre). (Photo by William Lovelace/Express/Getty Images)'>
                    <Image image={mlkResting} alt="23rd May 1961: American clergyman and civil rights campaigner Martin Luther King (1929 - 1968) (centre). (Photo by William Lovelace/Express/Getty Images)" />
                  </div>
                   
                </div>
                <div className="chapter-3-scene-8__images__photos__photo union">
                  <div ref="kingImage3" role='img' aria-label="American civil rights activist Martin Luther King Jr. (1929 - 1968) addresses a meeting in Chicago, Illinois, 27th May 1966. (Photo by Jeff Kamen/Michael Ochs Archives/Getty Images)">
                    <Image image={mlkUnion} alt="American civil rights activist Martin Luther King Jr. (1929 - 1968) addresses a meeting in Chicago, Illinois, 27th May 1966. (Photo by Jeff Kamen/Michael Ochs Archives/Getty Images)" />
                  </div>
                   
                </div>
              </div>
            </div>
          }
          leftPanel={
            <div {...tabbable} className="chapter-3-scene-8__text">
              <p className="alt">King had just won the 1964 Nobel Peace Prize for his nonviolent resistance to racial prejudice in America. That empowered him with great moral authority to launch a national crusade for voting rights. </p>
              <p>That crusade was built on local campaigns
like Freedom Summer in Mississippi and
 voting rights efforts in <span className="highlight">Selma</span>.</p>
            </div>
          }
        />
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    kingImage1,
    kingImage2,
    kingImage3} = context.refs;

  TweenMax.set(kingImage1, {y: -100, ...hideElementWithAria});
  TweenMax.set(kingImage2, {y: -100, ...hideElementWithAria});
  TweenMax.set(kingImage3, {y: -100, ...hideElementWithAria});

  timeline.to(kingImage1, 1.5, {y: 0, ...showElemWithAria,...addTab, ...easeOut});
  timeline.to(kingImage2, 1.5, {y: 0, ...showElemWithAria,...addTab, ...easeOut}, '-=1.2');
  timeline.to(kingImage3, 1.5, {y: 0, ...showElemWithAria,...addTab, ...easeOut}, '-=1.2');

  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    map: file(
      relativePath: { eq: "chapter-3/scene-8/images/ch-3-image-map-alabama-mississippi@3x.jpg" }
    ) {
      ...largeImage
    }
    mlkPulpit: file(
      relativePath: { eq: "chapter-3/scene-8/images/ch-3-image-mlk-in-pulpit@3x.png" }
    ) {
      ...largeImage
    }
    mlkResting: file(
      relativePath: { eq: "chapter-3/scene-8/images/ch-3-image-mlk-resting@3x.png" }
    ) {
      ...mediumImage
    }
    mlkUnion: file(
      relativePath: { eq: "chapter-3/scene-8/images/ch-3-image-mlk-speaking-union@3x.png" }
    ) {
      ...mediumImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S8, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
