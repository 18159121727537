import React, {Component} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';
import {Expo} from 'gsap/TweenMax';
import {tabbable} from 'utils/accessibility'

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import SplitView from 'templates/SplitView';
import {
  placeholder, showElemWithAria, hideElementWithAria
} from 'utils/animation';

import scene from 'components/scene'
import './chapter-3-scene-13.scss';
import { addTab, removeTab } from '../../../../utils/animation';

const C3S13 = class extends Component {
  constructor(props) {
    super(props);

    const imageRefs = [
      'leftImageB',
      'leftImageArrow',
      'rightImageB',
      'rightImageArrow',
    ];

    imageRefs.map((name) => this[name] = React.createRef());
  }

  render() {
    const {
      connor1,
      connor2,
      connorArrow,
      clark1,
      clark2,
      clarkArrow,
      background,
      protestors,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-13">
        <div className="chapter-3-scene-13__protestors">
          <div
            ref="protestBackground"
            aria-label="BOSTON, MA - MARCH 9: Civil rights demonstrators picket with signs demanding federal protection for the protest marchers of Selma, AL outside of the Federal Building in Boston on March 9, 1965. (Photo by Ed Farrand/The Boston Globe via Getty Images)"
            className="chapter-3-scene-13__protestors__background">
            <Image image={background}
              sizes="100vw"
              alt="BOSTON, MA - MARCH 9: Civil rights demonstrators picket with signs demanding federal protection for the protest marchers of Selma, AL outside of the Federal Building in Boston on March 9, 1965. (Photo by Ed Farrand/The Boston Globe via Getty Images)"
            />
          </div>
          <div
            {...tabbable}
            ref="protestText"
            className="chapter-3-scene-13__protestors__text">
            <h1 className="uppercase">That confrontation</h1>
            <p>would draw national attention to the struggle for the vote.</p>
          </div>

          <div
            ref="protestImage"
            className="chapter-3-scene-13__protestors__image">
            <Image image={protestors}

              sizes="100vw"
              alt="protestors foreground" />
          </div>
        </div>
        <SplitView
          leftPanel={
            <div className="chapter-3-scene-13__left">
              <div className="chapter-3-scene-13__image-swap">
                <div
                  ref="leftImageAContainer"
                  className="chapter-3-scene-13__image-swap__image imageA">
                  <Image image={clark1}
                    alt="Jim Clark"
                    sizes="50vw"
                  />
                </div>
                <div
                  ref="leftImageBContainer"
                  className="chapter-3-scene-13__image-swap__image imageB" >
                  <div ref="leftImageB" role='img' aria-label="(Original Caption) The Selma Story. Selma, Alabama: Dallas County Sheriff James G. Clark appears on the steps of the court house, January 22, three days before he was punched in the face by a negro woman in the same area. The negroes of Selma, led by Dr. Martin Luther King, Jr., were testing voter registration. January 22, 1965."
                  >
                    <Image
                      alt="(Original Caption) The Selma Story. Selma, Alabama: Dallas County Sheriff James G. Clark appears on the steps of the court house, January 22, three days before he was punched in the face by a negro woman in the same area. The negroes of Selma, led by Dr. Martin Luther King, Jr., were testing voter registration. January 22, 1965."
                      image={clark2}
                      sizes="50vw" />
                  </div>
                  <div ref="nameLabel1" className="name-label">
                    <p className="name">Jim Clark</p>
                    <div ref="leftImageArrow">
                      <Image
                        alt="Jim Clark label"
                        image={clarkArrow} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          rightPanel={
            <div className="chapter-3-scene-13__right">
              <div className="chapter-3-scene-13__image-swap">
                <div
                  ref="rightImageAContainer"
                  className="chapter-3-scene-13__image-swap__image imageA">
                  <Image
                    image={connor1}
                    alt="Bull Conotor"
                    sizes="50vw"
                  />
                </div>
                <div
                  ref="rightImageBContainer"
                  className="chapter-3-scene-13__image-swap__image imageB">
                  <div ref="rightImageB" role='img' aria-label="American politician and Commissioner of Public Safety Bull Connor (born Theophilus Connor, 1897 - 1973) in Kelly Ingram Park, Birmingham, Alabama, 1963. Connor's staunch opposition to integration led to his authorization of the use of firehoses, dogs, and teargas against demonstrators, hundreds of injuries, and thousands of arrests. (Photo by Charles Moore/Getty Images)">
                    <Image
                      image={connor2}
                      alt=""
                      sizes="50vw" />
                  </div>
                  <div ref="nameLabel2" className="name-label">
                    <p className="name">Bull Connor</p>
                    <div ref="rightImageArrow">
                      <Image alt="Bull Connor label" image={connorArrow} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          fullWidthPanel={
            <div {...tabbable} ref="fullWidthText" aria-label=" Finally, King knew from experience with Birmingham's Bull
            Connor in 1963 that the antagonistic and violent tendencies of
            Dallas County Sheriff Jim Clark would ensure a confrontation. Both sheriffs were bullies who were easily provoked." className="chapter-3-scene-13__full-width__text">
              <p className="alt">
                Finally, King knew from experience with Birmingham's Bull
                Connor in 1963 that the antagonistic and violent tendencies of
                Dallas County Sheriff Jim Clark would{' '}
                <span className="highlight">ensure a confrontation</span>.
              </p>
              <p className="alt">
                Both sheriffs were bullies who were easily provoked.
              </p>
            </div>
          }
        />
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    leftImageAContainer,
    leftImageBContainer,
    leftImageB,
    rightImageAContainer,
    rightImageBContainer,
    rightImageB,
    fullWidthText,
    protestBackground,
    protestImage,
    protestText,
    nameLabel1,
    nameLabel2,
  } = context.refs;

  TweenMax.set([nameLabel1, nameLabel2, protestBackground], {...hideElementWithAria});
  TweenMax.set([leftImageAContainer, rightImageAContainer], {...showElemWithAria});
  TweenMax.set([leftImageBContainer, rightImageBContainer], {y: '100%'});
  TweenMax.set([leftImageB, rightImageB], {z: 0, x: 0, y: '-100%', ...addTab});
  TweenMax.set(fullWidthText, {...hideElementWithAria, y: 50});
  TweenMax.set(protestText, {...hideElementWithAria, z: -100});
  TweenMax.set(protestImage, {...hideElementWithAria, y: '0%', z: 0});

  timeline.to(leftImageBContainer, 2, {y: '0%', ...easeOut});
  timeline.to(leftImageB, 2, {y: '0%', x: '0%', z: 0, ...addTab, ...easeOut}, '-=2');
  timeline.to(rightImageBContainer, 2, {y: '0%', ...easeOut}, '-=2');
  timeline.to(rightImageB, 2, {y: '0%', x: '0%', z: 0, ...addTab, ...easeOut}, '-=2');
  timeline.to([nameLabel1, nameLabel2], 1, {...showElemWithAria}, "-=1.5");

  timeline.to(
    [rightImageAContainer, leftImageAContainer],
    1,
    {opacity: 0.25},
    '-=1.5'
  );

  timeline.to(fullWidthText, 1.5, {...showElemWithAria, ...addTab, y: 0, ...easeOut}, '-=0.5');

  timeline.markers.createTimelineMarker();
  timeline.to(fullWidthText, 0.5, {...hideElementWithAria, y: -50});
  timeline.to(
    [nameLabel1, nameLabel2, leftImageAContainer, rightImageAContainer],
    0.5,
    {...hideElementWithAria},
    '-=0.5'
  );
  timeline.to(
    leftImageB,
    1,
    {z: 500, x: '-100%', y: '25%', ...removeTab, ...easeIn},
    '-=0.25'
  );
  timeline.to(
    rightImageB,
    1,
    {z: 500, x: '100%', y: '25%', ...removeTab, ...easeIn},
    '-=1'
  );
  timeline.to(
    protestImage,
    2,
    {z: 100, y: '0%', ...showElemWithAria, ease: Expo.easeInOut},
    '-=1'
  );
  timeline.to(protestBackground, 1, {...showElemWithAria, ...addTab}, '-=1');

  timeline.to(protestImage, 1, {z: 1000, ...easeIn});
  timeline.to(
    protestText,
    1,
    {...showElemWithAria, z: 0, ...easeOut},
    '-=0.25'
  );

  placeholder(timeline, 0.5);
  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    connor1: file(
      relativePath: { eq: "chapter-3/scene-13/images/ch-3-image-bull-connor-bw@3x.jpg" }
    ) {
      ...largeImage
    }
    connor2: file(
      relativePath: { eq: "chapter-3/scene-13/images/ch-3-image-bull-connor-red@3x.png" }
    ) {
      ...largeImage
    }
    connorArrow: file(
      relativePath: { eq: "chapter-3/scene-13/images/ch3-doodle-arrow-confrontation_bull_connor.png" }
    ) {
      ...doodleImage
    }
    clark1: file(
      relativePath: { eq: "chapter-3/scene-13/images/ch-3-image-jim-clark-bw@2x.png" }
    ) {
      ...mediumImage
    }
    clark2: file(
      relativePath: { eq: "chapter-3/scene-13/images/ch-3-image-jim-clark-red.png" }
    ) {
      ...mediumImage
    }
    clarkArrow: file(
      relativePath: { eq: "chapter-3/scene-13/images/ch3-doodle-arrow-confrontation_jim_clark.png" }
    ) {
      ...doodleImage
    }
    background: file(
      relativePath: { eq: "chapter-3/scene-13/images/ch-3-image-national-protestors@2x.jpg" }
    ) {
      ...largeImage
    }
    protestors: file(
      relativePath: { eq: "chapter-3/scene-13/images/ch3-image-national_protestors-sepia.png" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S13, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
