import React, {Component} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';
import {Expo} from 'gsap/TweenMax';
import {tabbable} from 'utils/accessibility'

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import {
  placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';

import scene from 'components/scene'
import './chapter-3-scene-11.scss';

const C3S11 = class extends Component {
  constructor(props) {
    super(props);

    const imageRefs = [
      'text1',
      'text2',
      'text3',
      'image1',
      'image2',
      'image3',
      'image4',
      'image5',
      'image6',
    ];

    imageRefs.map((name) => this[name] = React.createRef());
  }

  render() {
    const {
      flag,
      protestor,
      kkk,
      policeDog,
      riotPolice,
      cop,
      march,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-11">
        <div ref="backgroundImage" className="chapter-3-scene-11__images" role='img' aria-label="Youth sings at church rally">
          <Image image={march} className="chapter-3-scene-11__images__background" alt="Youth sings at church rally" />
        </div>
        <div className="chapter-3-scene-11__text">
          <p {...tabbable} ref="text1">
            Civil rights protests in the South since 1960 had provoked a strong
            white backlash: a voting rights campaign would do the same.
          </p>
        </div>
        <div {...tabbable} ref="text2" className="chapter-3-scene-11__text">
          <p className="large">
            But civil rights leaders believed the movement in Selma was strong enough to resist it.
          </p>
        </div>
        <div {...tabbable} ref="text3" className="chapter-3-scene-11__text left-align">
          <p>
            Since 1963, efforts by the Dallas County Voters League and SNCC to
            register black voters were increasingly organized and tenacious.
          </p>
          <p>
            There was now a deep local history of resistance that King and SCLC
            could ally with and build on.
          </p>
        </div>
        <div className="chapter-3-scene-11__images">
          <div className="chapter-3-scene-11__images__background" />
          <div ref="image1" className="image-container">
            <Image
              className="chapter-3-scene-11__images__image flag"
              image={flag}
              alt="man holding confederate flag"
            />
          </div>
          <div ref="image2" className="image-container">
            <Image
              className="chapter-3-scene-11__images__image protestor"
              image={protestor}
              alt="man holding sign reading 'keep america white'"
            />
          </div>
          <div ref="image3" className="image-container">
            <Image
              className="chapter-3-scene-11__images__image policeDog"
              image={policeDog}
              alt="policeman with barking dog"
            />
          </div>
          <div ref="image4" className="image-container">
            <Image
              className="chapter-3-scene-11__images__image kkk"
              image={kkk}
              alt="two KKK members"
            />
          </div>
          <div ref="image5" className="image-container">
            <Image
              className="chapter-3-scene-11__images__image riotPolice"
              image={riotPolice}
              alt="police arresting african american men"
            />
          </div>
          <div ref="image6" className="image-container">
            <Image
              className="chapter-3-scene-11__images__image cop"
              image={cop}
              alt="policeman pushing african american man"
            />
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    backgroundImage,
    text1,
    text2,
    text3,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
  } = context.refs;

  TweenMax.set(backgroundImage, {...hideElementWithAria});
  TweenMax.set([text1, text3], {...hideFromBelow});
  TweenMax.set(text2, {...hideElementWithAria, scale: 0.8});
  TweenMax.set(image1, {...hideElementWithAria, scale: 0.95, x: '0%', y: '0%'});
  TweenMax.set(image2, {...hideElementWithAria, scale: 0.95, x: '0%', y: '0%'});
  TweenMax.set(image3, {...hideElementWithAria, scale: 0.95, x: '0%', y: '0%'});
  TweenMax.set(image4, {...hideElementWithAria, scale: 0.95, x: '0%', y: '0%'});
  TweenMax.set(image5, {...hideElementWithAria, scale: 0.95, x: '-50%', y: '0%'});
  TweenMax.set(image6, {...hideElementWithAria, scale: 0.95, x: '0%', y: '0%'});

  timeline.to(text1, 1.5, {...revealFromBelow, ...easeOut});
  timeline.markers.createTimelineMarker();
  timeline.to(image1, 0.1, {...showElemWithAria, scale: 1});
  placeholder(timeline, 0.3);
  timeline.to(image2, 0.1, {...showElemWithAria, scale: 1});
  placeholder(timeline, 0.3);
  timeline.to(image3, 0.1, {...showElemWithAria, scale: 1});
  placeholder(timeline, 0.3);
  timeline.to(image4, 0.1, {...showElemWithAria, scale: 1});
  placeholder(timeline, 0.3);
  timeline.to(image5, 0.1, {...showElemWithAria, scale: 1});
  placeholder(timeline, 0.3);
  timeline.to(image6, 0.1, {...showElemWithAria, scale: 1});
  timeline.to(text1, 0.5, {...hideElementWithAria}, '-=0.5');

  placeholder(timeline, 1);

  timeline.to(image1, 1, {y: '100%', ...easeIn});
  timeline.to(image2, 1, {x: '100%', y: '-25%', ...easeIn}, '-=1');
  timeline.to(image3, 1, {x: '-100%', y: '50%', ...easeIn}, '-=1');
  timeline.to(image4, 1, {x: '50%', y: '100%', ...easeIn}, '-=1');
  timeline.to(image5, 1, {y: '-100%', ...easeIn}, '-=1');
  timeline.to(image6, 1, {x: '-100%', y: '-100%', ...easeIn}, '-=1');
  timeline.to(backgroundImage, 0.5, {...showElemWithAria}, '-=0.25');
  timeline.to(text2, 1.5, {...showElemWithAria, scale: 1, ...easeOut}, '-=0.5');

  timeline.markers.createTimelineMarker();
  timeline.to(text2, 1, {...hideElementWithAria, y: -50, ...easeOut});
  timeline.to(text3, 1, {...showElemWithAria, y: 0, ...easeOut});


};

const query = graphql`
  query {
    flag: file(
      relativePath: { eq: "chapter-3/scene-11/images/ch-3-image-confederate-flag-red.png" }
    ) {
      ...mediumImage
    }
    protestor: file(
      relativePath: { eq: "chapter-3/scene-11/images/ch-3-image-keep-alabama-white-red@2x.png" }
    ) {
      ...mediumImage
    }
    kkk: file(
      relativePath: { eq: "chapter-3/scene-11/images/ch-3-image-kkk-red@3x.png" }
    ) {
      ...mediumImage
    }
    policeDog: file(
      relativePath: { eq: "chapter-3/scene-11/images/ch-3-image-police-dog-red.png" }
    ) {
      ...mediumImage
    }
    riotPolice: file(
      relativePath: { eq: "chapter-3/scene-11/images/ch-3-image-riot-police@2x.png" }
    ) {
      ...mediumImage
    }
    cop: file(
      relativePath: { eq: "chapter-3/scene-11/images/ch-3-image-yelling-at-cop-with-bat-red@2x.png" }
    ) {
      ...mediumImage
    }
    march: file(
      relativePath: { eq: "chapter-3/scene-11/images/ch-3-image-strong-to-resist@2x.jpg" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S11, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
