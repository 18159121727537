import React from 'react';
import {TweenMax} from 'gsap';
import {Expo, Linear} from 'gsap/TweenMax';
import {useStaticQuery, graphql} from 'gatsby';

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import Quote from 'components/Quote';
import ScrollContainer from 'components/ScrollContainer';
import SplitView from 'templates/SplitView';
import {tabbable} from 'utils/accessibility';
import {makeElementTabbable, unmakeElementTabbable} from 'utils/animation';

import {
  showElemWithAria, hideElementWithAria,
} from 'utils/animation';

import './chapter-3-scene-2.scss';

import {areClipPathShapesSupported} from 'utils';

import scene from 'components/scene';

const C3S2 = class extends React.Component {
  render() {
    const {
      lafayette,
      map,
      usOutline,
      imageBlack,
      imageRed,
      imageSepia,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-2">
        <div ref={(ref) => this.intro = ref } className="chapter-3-scene-2__content-scene intro">
          <p {...tabbable} ref={(ref) => this.introText = ref}>The 1964 act also didn’t address economic retaliation, police repression, or physical violence against black voters in the South.</p>
          <div className="chapter-3-scene-2__content-scene__image">
            <Image image={imageBlack} alt="group of men holding clubs in black and white"/>
          </div>
        </div>

        <div ref={(ref) => this.redImageScene = ref} className="chapter-3-scene-2__content-scene red-treatment">
          <div ref={(ref) => this.redImage = ref} className="chapter-3-scene-2__content-scene__image">
            <Image image={imageRed} />
          </div>
        </div>

        <div ref={(ref) => this.protest = ref} className="chapter-3-scene-2__content-scene protest">
          <p {...tabbable} aria-label="But that would soon change—as civil rights campaigners began to organize in Mississippi and Alabama." ref={(ref) => this.protestText = ref}>But that would soon change—as civil rights campaigners began to organize in Mississippi and Alabama. </p>
          <div ref={(ref) => this.protestImage = ref} className="chapter-3-scene-2__content-scene__image">
            <Image showAria className="chapter-3-scene-2__content-scene__image__img" image={imageSepia} alt="March 1965: A line of policemen on duty during a black voting rights march in Montgomery, Alabama. Dr Martin Luther King led the march from Selma, Alabama, to the state capital in Montgomery. (Photo by William Lovelace/Express/Getty Images)" />
          </div>
        </div>

        <div ref={(ref) => this.protestSplit = ref} className="chapter-3-scene-2__split">
          <SplitView
            leftPanel={
              <Image showAria image={lafayette} alt="USA. Montgomery, Alabama. 1961. Civil rights activist Bernard LAFAYETTE about to board a bus during the Freedom Ride to desegregate buses. "/>
            }
            rightPanel={
              <div ref={(ref) => this.protestSplitRight = ref} className="chapter-3-scene-2__split__right">
                <ScrollContainer className="quote-container__scroll">
                  <div className="quote-container" ref={(ref) => this.protestQuote = ref}>
                    <Quote
                      quote="It [was] not something you read that causes you to change … It’s when you see other individuals fight against the system, and insist that justice will come, and believe that justice will come, even if you have to lose your life."
                      name="Bernard LaFayette"
                      title="Civil Rights Activist and Organizer"
                      position={1} />
                  </div>
                </ScrollContainer>
              </div>
            } />
        </div>

        <div ref={(ref) => this.mapScene = ref} className="chapter-3-scene-2__content-scene chapter-3-scene-2__map">
          <FullScreenContainer>
            <div className="chapter-3-scene-2__map__background" />
            <div ref={(ref) => this.mapContent = ref} className="chapter-3-scene-2__map__content">
              <div ref={(ref) => this.mapOutline = ref} className="chapter-3-scene-2__map__outline">
                <Image image={usOutline} alt="us map outline"/>
              </div>
              <div ref={(ref) => this.mapZoom = ref} className="chapter-3-scene-2__map__zoom">
                <Image image={map} alt="alabama map"/>
                <div ref={(ref) => this.mapText = ref} className="chapter-3-scene-2__map__zoom__text">
                  <h1 {...tabbable} >Mississippi's <br /><span className="highlight">Freedom Summer</span></h1>
                </div>
              </div>
            </div>
          </FullScreenContainer>
        </div>
      </FullScreenContainer>
    );
  }
};

const query = graphql`
  query {
    lafayette: file(
      relativePath: { eq: "chapter-3/scene-2/images/scene_2_lafayette.png" }
    ) {
      ...largeImage
    }
    map: file(
      relativePath: { eq: "chapter-3/scene-2/images/scene_2_map.jpg" }
    ) {
      ...largeImage
    }
    usOutline: file(
      relativePath: { eq: "chapter-3/scene-2/images/scene_2_us.png" }
    ) {
      ...largeImage
    }
    imageBlack: file(
      relativePath: { eq: "chapter-3/scene-2/images/scene_2_image_black.png" }
    ) {
      ...mediumImage
    }
    imageRed: file(
      relativePath: { eq: "chapter-3/scene-2/images/scene_2_image_red.png" }
    ) {
      ...mediumImage
    }
    imageSepia: file(
      relativePath: { eq: "chapter-3/scene-2/images/scene_2_image_sepia.png" }
    ) {
      ...mediumImage
    }
  }
`;

const animation = ({context, timeline, easeOut, easeIn}) => {
  TweenMax.set(context.protestSplit, {y: '100%'});
  TweenMax.set(context.protestSplitRight, {y: '-200%'});
  TweenMax.set(context.protestQuote, {y: '100%'});
  TweenMax.set([context.protest, context.protestSplit, context.mapScene], {...hideElementWithAria});
  TweenMax.set(context.mapZoom, {...hideElementWithAria, z: -500});
  TweenMax.set(context.mapOutline, {z: 250});
  TweenMax.set(context.mapText, {z: -250});

  const policeImage = '.image-container__image.full-res .chapter-3-scene-2__content-scene__image__img';

  timeline.fromTo(context.introText, 1.5, {y: '+=50', ...hideElementWithAria}, {...showElemWithAria, y: '-=50', ...easeOut});

  timeline.markers.createTimelineMarker();

  if (areClipPathShapesSupported()) {
    // TODO: refactor this into ImageSwap component
    const clipPoints1 = {y1: 115, y2: 100};
    const clipPoints2 = {y1: 115, y2: 100};

    const setClipPath = (target, clipPoints) => {
      const clipString = `polygon(0% ${clipPoints.y1}%, 100% ${clipPoints.y2}%, 100% 100%, 0% 100%)`;
      TweenMax.set(target, {webkitClipPath: clipString});
    };

    setClipPath(context.redImageScene, clipPoints1);
    setClipPath(context.protest, clipPoints2);

    timeline.to(clipPoints1, 2.5, {y1: 0, y2: -15, onUpdate: (e) => {
      setClipPath(context.redImageScene, clipPoints1);
    }, ease: Expo.easeInOut});
    timeline.fastShow(context.protest, '-=2.5');
    timeline.fastHide(context.intro);

    timeline.to(clipPoints2, 2.5, {y1: 0, y2: -15, onUpdate: (e) => {
      setClipPath(context.protest, clipPoints2);
    }, ease: Expo.easeInOut}, '-=2');
  } else {
    TweenMax.set(context.redImageScene, {y: '100%'});
    TweenMax.set(context.redImage, {y: '-100%'});
    TweenMax.set(context.protest, {y: '100%'});
    TweenMax.set(context.protestImage, {y: '-100%'});

    timeline.to(context.redImageScene, 2, {y: '0%', ease: Linear.easeNone});
    timeline.to(context.redImage, 2, {y: '0%', ease: Linear.easeNone}, '-=2');

    timeline.to(context.protest, 2, {y: '0%', ease: Linear.easeNone, ...showE}, '-=1');
    timeline.to(context.protestImage, 2, {y: '0%', ease: Linear.easeNone}, '-=2');
  }

  timeline.to(policeImage, 0.01, {...makeElementTabbable}, '-=0.01');

  timeline.markers.createTimelineMarker();
  timeline.to(context.protestText, 1, {...hideElementWithAria});
  timeline.to(policeImage, 0.01, {...unmakeElementTabbable}, '-=1');
  timeline.to(context.protestSplit, 2, {y: '0%', ease: Expo.easeInOut, ...showElemWithAria}, '-=1');
  timeline.to(context.protestSplitRight, 2, {y: '0%', ease: Expo.easeInOut}, '-=2');
  timeline.to(context.protestQuote, 2, {y: '0%', ease: Expo.easeInOut}, '-=2');
  timeline.markers.createTimelineMarker();
  timeline.to(context.mapScene, 1, {...showElemWithAria});
  timeline.to(context.mapOutline, 1.5, {z: 2500, ease: Expo.easeInOut});
  timeline.to(context.mapZoom, 1, {z: 0, ease: Expo.easeOut}, '-=1.25');
  timeline.fastHide(context.protest);
  timeline.fastHide(context.protestSplit);


  timeline.to(context.mapText, 1, {z: 0, ease: Expo.easeOut}, '-=1.25');
  timeline.to(context.mapZoom, 0.25, {...showElemWithAria}, '-=1');
  timeline.markers.createTimelineMarker();
};

const SceneComponent = scene(timeline(C3S2, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
