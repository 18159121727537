import React from 'react';
import { TweenMax } from 'gsap';
import { useStaticQuery, graphql } from 'gatsby';

import Image from 'components/Image';
import ScrollContainer from 'components/ScrollContainer';
import timeline from 'components/timelineComponent';
import SplitView, { viewTypes } from 'templates/SplitView';
import { tabbable } from 'utils/accessibility';

import './chapter-3-scene-3.scss';
import 'styles/components/vertical-timeline.scss';

import {
  showElemWithAria,
  hideElementWithAria,
  createLightThemeOnStartSetter,
  makeElementTabbable,
  unmakeElementTabbable
} from 'utils/animation';

import scene from 'components/scene';
import { addTab, removeTab } from '../../../../utils/animation';

const C3S3 = class extends React.Component {
  render() {
    const { graph, doodle, doodle2, arrow, fannieLou, mfdp } = this.props.data;

    return (
      <div className="chapter-3-scene-3">
        <SplitView
          viewType={viewTypes.TWO_THIRDS}
          leftPanel={
            <div className="chapter-3-scene-3__images">
              <div
                ref={ref => (this.image1 = ref)}
                className="chapter-3-scene-3__image graph">
                <Image
                  className="main-image"
                  image={graph}
                  alt="registered black voters chart"
                />
                <div className="graph__header">
                  <h3 className="graph__header__title">
                    Registered Black Voters
                  </h3>
                  <h4 className="graph__header__subtitle">Mississippi</h4>
                </div>
                <div aria-hidden={true} className="graph__doodle">
                  <Image image={doodle} alt="6.7% voters registered doodle" />
                  <p>6.7%</p>
                </div>
              </div>
              <div
                ref={ref => (this.image2 = ref)}
                className="chapter-3-scene-3__image fannie-lou">
                <div>
                  <Image
                    className="main-image"
                    image={fannieLou}
                    alt="FILE - In this Sept. 17, 1965 file photo, Fannie Lou Hamer, of Ruleville, Miss., speaks to Mississippi Freedom Democratic Party sympathizers outside the Capitol in Washington after the House of Representatives rejected a challenger to the 1964 election of five Mississippi representatives. Friday, Oct. 6, 2017 marks the 100th anniversary of her birth and a celebration is planned in her hometown of Ruleville, Miss., honoring her. (AP Photo/William J. Smith, File)"
                  />
                </div>
                <div
                  ref={ref => (this.fannieLouLabel = ref)}
                  className="fannie-lou__text">
                  <h3 className="fannie-lou__title">Fannie Lou Hamer</h3>
                  <ul className="fannie-lou__list">
                    <li>- Civil Rights Activist</li>
                    <li>- Community Organizer</li>
                    <li>
                      - Co-Founder / Vice-Chair of The Mississippi Freedom
                      Democratic Party
                    </li>
                  </ul>
                </div>
              </div>
              <div
                ref={ref => (this.image3 = ref)}
                className="chapter-3-scene-3__image mfdp">
                <Image
                  className="main-image"
                  showAria
                  image={mfdp}
                  alt="USA. Hattiesburg, MS. 1964. Voter registration."
                />
              </div>
            </div>
          }
          rightPanel={
            <div className="vertical-timeline__historic-timeline">
              <div className="chapter-3-scene-3__timeline vertical-timeline">
                <ul className="vertical-list">
                  <li className="time-event">
                    <ScrollContainer className="time-event__scroll-container">
                      <div className="time-event__content">
                        <h1 ref={ref => (this.title = ref)}>1964</h1>
                        <div
                          ref={ref => (this.paragraph1 = ref)}
                          className="subhead">
                          In 1964, Mississippi had the lowest black voter
                          registration rate in the nation:{' '}
                          <span className="highlight">6.7%</span>
                        </div>
                        <p
                          ref={ref => (this.paragraph2 = ref)}
                          className="alt"
                          aria-label="In June 1964, local activists led by Fannie Lou Hamer and
                        Aaron Henry allied with SNCC, the Congress on Racial
                        Equality (CORE), SCLC, and the NAACP to launch a major
                        voting rights project in the state.">
                          In June 1964, local activists led by Fannie Lou Hamer
                          and Aaron Henry allied with SNCC, the Congress on
                          Racial Equality (CORE), SCLC, and the NAACP to launch
                          a major voting rights project in the state.
                        </p>
                        <p
                          ref={ref => (this.paragraph3 = ref)}
                          {...tabbable}
                          className="alt"
                          aria-label=" These groups formed the Mississippi Freedom Democratic Party
                        (MFDP) to represent black voters excluded from the official,
                        all-white, state party.">
                          These groups formed the Mississippi Freedom Democratic
                          Party (MFDP) to represent black voters excluded from
                          the official, all-white, state party.
                        </p>
                      </div>
                    </ScrollContainer>
                  </li>
                </ul>
              </div>
            </div>
          }
        />
      </div>
    );
  }
};

const query = graphql`
  query {
    lafayette: file(
      relativePath: { eq: "chapter-3/scene-3/images/image_1964_a.png" }
    ) {
      ...largeImage
    }
    doodle: file(
      relativePath: { eq: "chapter-3/scene-3/images/image_1964_a_scribble.png" }
    ) {
      ...doodleImage
    }
    doodle2: file(
      relativePath: { eq: "chapter-3/scene-3/images/image_1964_b_scribble.png" }
    ) {
      ...doodleImage
    }
    arrow: file(
      relativePath: {
        eq: "chapter-3/scene-3/images/ch-3-arrow-fannie-lou-hamer@2x.png"
      }
    ) {
      ...doodleImage
    }
    fannieLou: file(
      relativePath: { eq: "chapter-3/scene-3/images/image_1964_b.jpg" }
    ) {
      ...largeImage
    }
    mfdp: file(
      relativePath: { eq: "chapter-3/scene-3/images/image_1964_c.jpg" }
    ) {
      ...largeImage
    }
    graph: file(
      relativePath: { eq: "chapter-3/scene-3/images/image_1964_a.png" }
    ) {
      ...smallImage
    }
  }
`;

const animation = ({ timeline, context, easeOut }) => {
  const setLightOnStart = createLightThemeOnStartSetter(context.props.setTheme);

  TweenMax.set([context.image2, context.image3], { ...hideElementWithAria });
  TweenMax.set([context.paragraph2, context.paragraph3], {
    ...hideElementWithAria,
    y: 50
  });
  TweenMax.set([context.title, context.paragraph1], { ...addTab });
  timeline.to(context.image1, 1, { ...hideElementWithAria, ...removeTab });
  timeline.to(context.image2, 1, {
    ...showElemWithAria,
    ...addTab,
    ...setLightOnStart
  });
  timeline.to(
    context.paragraph2,
    1.5,
    { ...showElemWithAria, y: 0, ...addTab, ...easeOut },
    '-=1'
  );
  timeline.to(
    [context.title, context.paragraph1],
    0,
    { ...unmakeElementTabbable },
    '-=1'
  );
  // timeline.to([context.fannieLouLabel], 0, { ...addTab }, '-=1');
  timeline.markers.createTimelineMarker();
  timeline.to(context.image2, 1, { ...hideElementWithAria, ...removeTab });
  timeline.to(context.image3, 1, { ...showElemWithAria });
  timeline.to(
    context.paragraph3,
    1.5,
    { ...showElemWithAria, ...addTab, y: 0, ...easeOut },
    '-=1'
  );
  timeline.to(
    [context.paragraph2, context.fannieLouLabel],
    0,
    { ...removeTab },
    '-=1'
  );
};

const SceneComponent = scene(timeline(C3S3, { animation }), {
  stopAtStart: true
});
export default props => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
