import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';
import SelmaScene1Modal from 'components/scenes/chapter-2/scene-2/SelmaScene1Modal';
import scene from 'components/scene';

import clip from 'components/scenes/chapter-3/scene-8/ch3-SCENE-02-MLKs_Voting_Rights_Act_vs_LBJs_Great_Society.mp4';

import './chapter-3-scene-8-clip.scss';

const Chapter3Scene8Clip = ({data}) => (
  <div className="chapter-3-scene-8-clip">
    <MovieScene
      sceneNumber={2}
      sceneDescription={`MLK’s Voting Rights Act vs. LBJ’s Great Society`}
      sceneSubtitle={
        <span>
          <span className="italic">SELMA</span> SCENE 2
        </span>
      }
      firstImage={data.firstImage}
      lastImage={data.firstImage}
      videoSrc={clip}
    />

  </div>
);

const query = graphql`
  query {
      firstImage: file(relativePath: { eq: "chapter-3/scene-8/images/ch-3-image-lbj-mlk-oval-office@3x.jpg" }) {
        ...largeImage
      }
  }
`;

const Component = scene(Chapter3Scene8Clip);
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};
