import React from 'react';
import { graphql } from 'gatsby';

import ChapterPage from 'components/chapter';
import ChapterIntro from 'templates/ChapterIntro';
import ChapterConclusion from 'components/ChapterConclusion';

import Scene1 from 'components/scenes/chapter-3/scene-1/Chapter3Scene1';
import Scene2 from 'components/scenes/chapter-3/scene-2/Chapter3Scene2';
import Scene3 from 'components/scenes/chapter-3/scene-3/Chapter3Scene3';
import Scene4 from 'components/scenes/chapter-3/scene-4/Chapter3Scene4';
import Scene5 from 'components/scenes/chapter-3/scene-5/Chapter3Scene5';
import Scene6 from 'components/scenes/chapter-3/scene-6/Chapter3Scene6';
import Scene7 from 'components/scenes/chapter-3/scene-7/Chapter3Scene7';
import Scene8 from 'components/scenes/chapter-3/scene-8/Chapter3Scene8';
import Scene8Clip from 'components/scenes/chapter-3/scene-8/Chapter3Scene8Clip';
import Scene9 from 'components/scenes/chapter-3/scene-9/Chapter3Scene9';
import Scene10 from 'components/scenes/chapter-3/scene-10/Chapter3Scene10';
import Scene11 from 'components/scenes/chapter-3/scene-11/Chapter3Scene11';
import Scene12 from 'components/scenes/chapter-3/scene-12/Chapter3Scene12';
import Scene13 from 'components/scenes/chapter-3/scene-13/Chapter3Scene13';
import Scene14 from 'components/scenes/chapter-3/scene-14/Chapter3Scene14';


import { Chapter3Qa } from 'components/scenes/chapter-3/scene-15/Chapter3Qa.js';

class Chapter3 extends React.Component {

  render() {
    const { SceneComponent, data } = this.props;
    const { screenHeight } = this.context;

    const { heroImage, Chapter3End } = data;

    const title = 'Youth Activism & Voting Rights'

    return (
      <div className="chapter-3">
        <ChapterPage
          chapterNumber={2}
          title={title}
          scenes={[
            <ChapterIntro
              title="Chapter 3"
              subtitle={title}
              hero={heroImage}
              alt="Demonstrators lock arms in front of the Dallas County courthouse in Selma, Alabama. Sheriff Jim Clark had them all arrested, 196"
            />,
            <Scene1 />,
            <Scene2 />,
            <Scene3 theme="dark" />,
            <Scene4 theme="dark" />,
            <Scene5 />,
            <Scene6 />,
            <Scene7 />,
            <Scene8 />,
            <Scene8Clip />,
            <Scene9 />,
            <Scene10 theme="dark"/>,
            <Scene11 />,
            <Scene12 />,
            <Scene13 />,
            <Scene14 theme="dark"/>,
            <ChapterConclusion
              image={Chapter3End}
              questions={Chapter3Qa}
              chapterNumber={3}
              nextChapterTitle={'Who’s Who in Selma'}
              theme="dark"
            />
          ]} />
      </div>
    );
  }
}

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "chapter-3/background@2x.jpg" }
    ) {
      ...largeImage
    }
    Chapter3End: file(
      relativePath: { eq: "chapter-3/scene-15/images/ch-3-image-mlk-pointing-map@3x.jpg" }
    ) {
      ...largeImage
    }
  }
`;

export default Chapter3
