import React, { Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { TweenMax } from 'gsap';
import Modal from 'components/ChapterModal';
import {
  stickyPositions,
  modalWindowBackgrounds
} from 'components/ModalContent';
import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import {tabbable} from 'utils/accessibility'
import SplitView from 'templates/SplitView';
import {
  placeholder, hideFromBelow, revealFromBelow
} from 'utils/animation';
import scene from 'components/scene'


import './chapter-3-scene-12.scss';

const C3S12 = class extends Component {
  render() {
    const { james, modalPhoto } = this.props.data;

    return (
      <SplitView className="chapter-3-scene-12"
        leftPanel={
          <div className="chapter-3-scene-12__images">
            <div ref="image">
              <Image
                className="chapter-3-scene-12__images__image meeting"
                image={james} showAria
                alt="Civil Rights activist, Reverend James Bevel in Selma, Alabama. Bevel proposed and organized the Selma to Montgomery March that would draw national attention to the civil rights movement in Alabama. (Photo by Steve Schapiro/Corbis via Getty Images)"
              />
            </div>
          </div>
        }
        rightPanel={
          <div className="chapter-3-scene-12__texts">
            <div
              ref="text"
              {...tabbable}
              className="chapter-3-scene-12__texts__text chapter-3-scene-12__texts__meeting right-align">
              <p>
                In late 1964 King's SCLC ally, Jim Bevel, worked with the DVLC and
                SNCC to ensure that Selma's African American community was{' '}
                <span className="highlight">united and ready</span>.<br />
              </p>
            </div>
          </div>
        }
      />
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const { text  } = context.refs;
  TweenMax.set(text, { ...hideFromBelow });
  timeline.to(text, 1.5, { ...revealFromBelow, ...easeOut });


};

const query = graphql`
  query {
    james: file(
      relativePath: {
        eq: "chapter-3/scene-12/images/ch-3-image-jamesbevel@2x.jpg"
      }
    ) {
      ...largeImage
    }
    modalPhoto: file(
      relativePath: { eq: "chapter-3/scene-12/images/actor-common.jpg" }
    ) {
      ...mediumImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S12, { animation }));
export default props => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
