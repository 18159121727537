import React from 'react';
import {TweenMax} from 'gsap';
import {useStaticQuery, graphql} from 'gatsby';
import {tabbable} from 'utils/accessibility'

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import SplitView from 'templates/SplitView';
import {
  placeholder, showElemWithAria, hideElementWithAria
} from 'utils/animation';

import scene from 'components/scene'
import './chapter-3-scene-5.scss';

const C3S5 = class extends React.Component {
  constructor(props) {
    super(props);

    const imageRefs = [
      'imageA',
      'imageB',
    ];

    imageRefs.map((name) => this[name] = React.createRef());
  }

  render() {
    const {
      bricks,
      bricksRed,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-5">
        <SplitView
          fullWidthPanel={
            <div className="chapter-3-scene-5__bricks">
              <div ref="imageA">
                <Image image={bricks} showAria alt="1964: An FBI poster seeking information as to the whereabouts of Andrew Goodman, James Earl Chaney and Michael Henry Schwerner, Civil Rights campaigners who went missing in Mississippi. (Photo by MPI/Getty Images)"/>
              </div>
              <div ref="imageB">
                <Image image={bricksRed} alt="missing persons poster for James Chaney, Andrew Goodman, and Michael Schwerner"/>
              </div>
            </div>
          }
          leftPanel={
            <div className="chapter-3-scene-5__text" ref="text">
              <p {...tabbable}>Three young volunteers—James Chaney, Andrew Goodman, and Michael Schwerner—were killed by members of the Ku Klux Klan.</p>
            </div>
          }
        />
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {imageA, imageB, text} = context.refs;

  TweenMax.set(imageA, {...showElemWithAria});
  TweenMax.set(imageB, {...showElemWithAria});
  TweenMax.set(text, {...hideElementWithAria});
  placeholder(timeline, 0.5);
  timeline.to(imageB, 1, {...hideElementWithAria});
  timeline.to(text, 1, {...showElemWithAria}, '-=1');

};

const query = graphql`
  query {
    bricks: file(
      relativePath: { eq: "chapter-3/scene-5/images/ch3-video-chaney_goodman_schwerner_poster-b@2x.jpg" }
    ) {
      ...largeImage
    }
    bricksRed: file(
      relativePath: { eq: "chapter-3/scene-5/images/ch3-video-chaney_goodman_schwerner_poster-a@2x.jpg" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S5, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
