import React, {Component} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import {tabbable} from 'utils/accessibility'
import scene from 'components/scene';
import Modal from 'components/ChapterModal';
import {stickyPositions} from 'components/ModalContent';
import {
  placeholder, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';

import './chapter-3-scene-9.scss';
import { addTab, removeTab } from '../../../../utils/animation';

const C3S9 = class extends Component {
  constructor(props) {
    super(props);

    const imageRefs = [
      'map1',
      'map2',
      'map3',
      'map4',
      'map5',
      'whySelmaImage',
    ];

    imageRefs.map((name) => this[name] = React.createRef());
  }

  render() {
    const {
      map1,
      map2,
      map3,
      map4,
      map5,
      bridge,
      map4Arrow,
      map4Circle,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-9">
        <div className="chapter-3-scene-9__zoom-image" ref="map1">
          <FullScreenImage
            image={map1}
            alignBottom
            alt="map of Alabama"
            aspectRatio={8/5}
          >
            <div className="map1Label" ref="map1Label">
              <Image className="circle" image={map4Circle} alt=" map of Alabama selma highlight"/>
              <p {...tabbable} className='innerText'>Selma</p>
            </div>
          </FullScreenImage>
        </div>

        <div className="image-container-alt" ref="map2">
          <Image className="chapter-3-scene-9__zoom-image" image={map2} alt="map of Selma"/>
        </div>

        <div className="chapter-3-scene-9__zoom-image" ref="map3">
          <FullScreenImage
            image={map3}
            alt="map of Edmund Pettus Bridge"
            aspectRatio={8/5}
          >
            <div className="map3Label" ref="map3Label">
              <Image className="circle" image={map4Circle} alt="circle labeling Edmund Pettus Bridge"/>
              <Image className="arrow" image={map4Arrow} alt="arrow pointing to Edmund Pettus Bridge"/>
              <p>Edmund Pettus Bridge</p>
            </div>
          </FullScreenImage>
        </div>

        <div className="image-container-alt" ref="map4">
          <Image className="chapter-3-scene-9__zoom-image" image={map4} alt="zoomed in map of Edmund Pettus Bridge" />
        </div>

        <div className="image-container-alt" ref="map5" role='img' aria-label="UNITED STATES - FEBRUARY 14: The Edmund Pettus Bridge over the Alabama River in Selma, Ala., was the scene of Bloody Sunday on March 7, 1965, when police beat protesters to prevent them from marching to Montgomery for voting rights. (Photo By Bill Clark/CQ Roll Call)">
          <Image className="chapter-3-scene-9__zoom-image" image={map5} alt="photo of Edmund Pettus Bridge"/>

          <div className="modal-section" ref="modal">
            <Modal
              className="chapter-3-scene-9-modal"
              stickyPosition={stickyPositions.NO_STICKY}>
              <div className="chapter-3-scene-9-modal__content">
                <h3>The Edmund Pettus Bridge</h3>
                <p className="alt">
                  The Edmund Pettus Bridge crosses the Alabama River in Selma. It
                  was named after a former Democratic U.S. Senator, Confederate
                  Brigadier General, and Grand Dragon of the Alabama Ku Klux Klan.
                </p>
                <p className="alt">
                  It figures in Bloody Sunday, Turnaround Tuesday, and the
                  successful march to the capitol. The bridge was declared a
                  National Historic Landmark in 2013.
                </p>
                <p className="alt">
                  There have been calls to rename it, but for now it retains the
                  original name.
                </p>
              </div>
            </Modal>
          </div>
        </div>
        <div ref="whySelma" className="chapter-3-scene-9__why-selma">
          <div className="chapter-3-scene-9__why-selma__background">
            <div className="image-container" ref="whySelmaImage">
              <Image
                className="chapter-3-scene-9__why-selma__background__image"
                image={bridge}
                alt="photo of crowd at Edmund Pettus Bridge"
              />
            </div>
          </div>
          <div
            className="chapter-3-scene-9__why-selma__content">
            <h1 {...tabbable} ref="whySelmaText1" className="uppercase">So why Selma?</h1>
            <p {...tabbable} ref="whySelmaText2" className="alt">
              Although student activism had created opportunities for
              demonstrations throughout the south, what made King choose Selma
              as the “next battleground” for voting rights in January 1965?
            </p>
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    map1,
    map2,
    map3,
    map4,
    map5,
    whySelma,
    whySelmaImage,
    whySelmaText1,
    whySelmaText2,
    modal,
    map3Label,
    map1Label,
  } = context.refs;

  TweenMax.set([modal, map1Label, map3Label], {...hideElementWithAria});
  TweenMax.set(map1, {z: 0, rotation: '0deg', x: 0, y: 0, ...showElemWithAria});
  TweenMax.set(map2, {
    z: -250,
    rotation: '0deg',
    x: '15%',
    y: '15%',
    rotation: '-15deg',
    ...hideElementWithAria,
  });
  TweenMax.set(map3, {
    z: 0,
    rotation: '0deg',
    x: '0%',
    y: '0%',
    rotation: '-10deg',
    ...hideElementWithAria,
  });
  TweenMax.set(map4, {
    z: 0,
    rotation: '-25deg',
    z: 0,
    x: 0,
    y: 0,
    ...hideElementWithAria,
  });
  TweenMax.set(map5, {z: 0, rotation: '0deg', x: 0, y: 0, ...hideElementWithAria});
  TweenMax.set(whySelma, {...hideElementWithAria});
  TweenMax.set(whySelmaImage, {...showElemWithAria});
  TweenMax.set([whySelmaText1, whySelmaText2], {...hideFromBelow});

  timeline.to(map1Label, 1, {...showElemWithAria, ...easeIn});
  timeline.markers.createTimelineMarker();
  timeline.to(map1, 1, {
    x: '-40%',
    y: '-30%',
    z: 1000,
    rotation: '15deg',
    ...hideElementWithAria,
    ...easeIn,
  });
  // timeline.to(map1Label, 1, {z: 350, y: '-100%', x: '-100%', ...hideElementWithAria, ...easeOut}, '-=0.25');
  timeline.to(
    map2,
    1,
    {
      ...showElemWithAria,
      z: 100,
      x: '-5%',
      y: '0%',
      rotation: '0deg',
      ...easeOut,
    },
    '-=0.25'
  );

  timeline.to(map2, 1, {
    z: 1000,
    x: '-35%',
    y: '-25%',
    rotation: '15deg',
    ...easeIn,
  });
  timeline.to(
    map3,
    1,
    {...showElemWithAria, z: 100, rotation: '0deg', ...easeOut},
    '-=0.25'
  );
  timeline.to(map3Label, 1, {...showElemWithAria}, '-=1');

  // timeline.to(map3Label, 1, {z: 1000, ...hideElementWithAria, ...easeIn});
  timeline.to(map3, 1, {
    z: 1000,
    x: '5%',
    rotation: '90deg',
    ...easeIn,
  });
  timeline.to(
    map4,
    1,
    {...showElemWithAria, z: 200, rotation: '0deg', ...easeOut},
    '-=0.25'
  );

  timeline.to(map4, 1, {z: 1000, rotation: '15deg', ...easeIn});
  timeline.to(map5, 1, {...showElemWithAria, ...addTab, z: 100, ...easeOut}, '-=0.25');
  timeline.to(modal, 0.5, {...showElemWithAria, ...easeOut}, '-=0.25');

  timeline.markers.createTimelineMarker();

  timeline.to(modal, 1, {...hideElementWithAria, ...easeOut});
  timeline.to(map5, 1, {...hideElementWithAria, ...removeTab}, '-=1');
  timeline.to(whySelma, 1, {...showElemWithAria}, '-=0.5');
  timeline.to(whySelmaImage, 1, {opacity: 0.25}, "-=1");
  timeline.to(whySelmaText1, 1.5, {...revealFromBelow, ...easeOut}, '-=0.5');
  timeline.to(whySelmaText2, 1.5, {...revealFromBelow, ...easeOut}, '-=1.25');
};

const query = graphql`
  query {
    map1: file(
      relativePath: { eq: "chapter-3/scene-9/images/ch-3-image-map-alabama@3x.jpg" }
    ) {
      ...largeImage
    }
    map2: file(
      relativePath: { eq: "chapter-3/scene-9/images/ch-3-image-map-alabama-roads.jpg" }
    ) {
      ...mediumImage
    }
    map3: file(
      relativePath: { eq: "chapter-3/scene-9/images/ch-3-image-map-selma-aerial@2x.jpg" }
    ) {
      ...largeImage
    }
    map4: file(
      relativePath: { eq: "chapter-3/scene-9/images/ch-3-image-map-alabama-pettus-overhead.jpg" }
    ) {
      ...largeImage
    }
    map5: file(
      relativePath: { eq: "chapter-3/scene-9/images/ch3-image-map_selma_bridge_modern.jpg" }
    ) {
      ...largeImage
    }
    bridge: file(
      relativePath: { eq: "chapter-3/scene-9/images/ch-3-image-selma-bridge-march@3x.jpg" }
    ) {
      ...largeImage
    }
    map4Arrow: file(
      relativePath: { eq: "chapter-3/scene-9/images/ch-3-arrow-edmund-pettus-bridge@2x.png" }
    ) {
      ...doodleImage
    }
    map4Circle: file(
      relativePath: { eq: "chapter-3/scene-9/images/ch-3-circle-edmund-pettus-bridge@2x.png" }
    ) {
      ...doodleImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S9, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
