import React, {Component} from 'react';
import {TweenMax} from 'gsap';
import {useStaticQuery, graphql} from 'gatsby';
import {tabbable} from 'utils/accessibility'

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import {
  showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow, makeElementTabbable, unmakeElementTabbable
} from 'utils/animation';
import scene from 'components/scene';


import './chapter-3-scene-14.scss';
import { addTab, removeTab } from '../../../../utils/animation';

const C3S14 = class extends Component {
  render() {
    const {
      kingBg,
      kingFg,
      lineup,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-14">
        <div className="chapter-3-scene-14__confrontation">
          <div className="chapter-3-scene-14__confrontation__text">
            <p ref="text" className="uppercase">The scene for a dramatic confrontation on voting rights — and a response from the president and congress — <br></br> was now set.</p>
          </div>
          <div ref="police" className="chapter-3-scene-14__confrontation__image" role='img' aria-label="Alabama state highway patrol officers line up across a road to block a civil rights march from leaving Selma. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)">
            <Image image={lineup} sizes="100vw" alt="Alabama state highway patrol officers line up across a road to block a civil rights march from leaving Selma. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)" />
          </div>
        </div>
        <div ref="kingScene" className="chapter-3-scene-14__king">
          <div ref="kingImages" aria-label="Martin Luther King, Jr. speaks at a rally encouraging blacks to register and vote in Alabama. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)" className="chapter-3-scene-14__king__images">
            <Image image={kingBg} sizes="100vw" alt="King in front of a crowd foreground" />
            <Image image={kingFg} sizes="100vw" alt="King in front of a crowd background" />
          </div>
          <div ref="kingText" className="chapter-3-scene-14__king__text">
            <h1 {...tabbable} className="uppercase">King arrived in Selma on January 2, 1965.</h1>
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, sceneId, setTheme, easeOut}) => {
  const {text, kingScene, police, kingImages, kingText} = context.refs;

  TweenMax.set(kingScene, {...hideElementWithAria});
  TweenMax.set([text, kingText], {...hideFromBelow});

  timeline.to(text, 1.5, {...revealFromBelow, ...addTab, ...easeOut})
  timeline.to(text, 0, {...makeElementTabbable}, "-=1.5")
  timeline.to(police, 0, {...addTab}, "-=1.5")

  timeline.markers.createTimelineMarker()

  timeline.to([police, text], 0, {...unmakeElementTabbable, ...removeTab})

  timeline.to(kingScene, 1, {
    ...showElemWithAria,
    onComplete: () => setTheme('light'),
    onReverseComplete: () => setTheme('dark')
  });
  timeline.to(kingText, 1.5, {y: 0, ...showElemWithAria, ...easeOut}, '-=0.5');
  timeline.to(kingImages, 0.01, { ...addTab}, '-=0.5');


};

const query = graphql`
  query {
    kingBg: file(
      relativePath: { eq: "chapter-3/scene-14/images/ch-3-image-mlk-hero-bw@2x.jpg" }
    ) {
      ...largeImage
    }
    kingFg: file(
      relativePath: { eq: "chapter-3/scene-14/images/ch-3-image-mlk-hero-sepia@2x.png" }
    ) {
      ...largeImage
    }
    lineup: file(
      relativePath: { eq: "chapter-3/scene-14/images/ch-3-image-police-lineup@3x.png" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S14, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
