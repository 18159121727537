import React, {Component} from 'react';
import {TweenMax} from 'gsap';
import {useStaticQuery, graphql} from 'gatsby';
import {tabbable} from 'utils/accessibility'

import Image from 'components/Image';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import SplitView from 'templates/SplitView';
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, hideFromBelow, revealFromBelow
} from 'utils/animation';


import scene from 'components/scene'
import './chapter-3-scene-7.scss';
import { addTab, removeTab } from '../../../../utils/animation';

const C3S7 = class extends Component {
  constructor(props) {
    super(props);

    const imageRefs = [
      'mlkBg',
      'dncBg',
      'text3',
      'image1',
      'image2',
      'image3',
      'image4',
      'image5',
      'image6',
    ];

    imageRefs.map((name) => this[name] = React.createRef());
  }

  render() {
    const {
      dncBg,
      lbj,
      mlk,
      mlkBg,
    } = this.props.data;

    return (
      <FullScreenContainer className="chapter-3-scene-7">
        <SplitView
          fullWidthPanel={
            <FullScreenContainer className="chapter-3-scene-7__images">
              <div className="chapter-3-scene-7__images__image chapter-3-scene-7__images__fannie">
                <div ref="mlkBg">
                  <Image
                    className="fill-height"
                    image={mlkBg}
                    alt="MLK sitting down background"
                  />
                </div>
                <div ref="dncBg">
                  <Image
                    className="chapter-3-scene-7__images__fannie__one"
                    image={dncBg}
                    alt="the democratic national convention"
                  />
                </div>
              </div>
              <div className="chapter-3-scene-7__images__image chapter-3-scene-7__images__lbj">
                <div
                  ref="lbjImageContainer"
                  className="chapter-3-scene-7__images__lbj__image">
                  <div
                    className="chapter-3-scene-7__images__lbj__content"
                    ref="lbjImage" aria-label="(Original Caption) Stricken with a heart attack several months ago, Senator Lyndon B. Johnson, Texas Democrat and Senate Majority Leader, returned to Capitol Hill yesterday and was given a warm welcome by the Capital Press Corps. At left the Senator is wagging a finger at the newsmen as he made a point. At right a reporter who was cheated on height stands of a file cabinet and strains to hear and see what's going on." role='img'>
                    <Image image={lbj} alt=""/>
                    <p>
                      President <br />
                      Lyndon B. Johnson
                    </p>
                  </div>
                </div>
              </div>
              <div
                ref="mlkImage"
                className="chapter-3-scene-7__images__image mlk">
                <Image image={mlk} showAria
                  alt="Close-up of, from left, Civil Rights leaders Coretta Scott King (1927 - 2006), Martin Luther King Jr (1929 - 1968), Ralph Abernathy (1926 - 1990), and one of his sons on the podium in front of the Alabama State Capitol, Montgomery, Alabama, March 25, 1965. King is reviewing his 'How Long? Not Long!' speech. (Photo by Kip Shaw/Charles Shaw/Getty Images)"
                />
              </div>
            </FullScreenContainer>
          }
          leftPanel={
            <div ref="leftText" className="chapter-3-scene-7__left">
              <p {...tabbable} ref="leftTextP1" aria-label="President Lyndon Johnson, however, blocked Ms. Hamer’s efforts
                to seat the MFDP as official delegates to the DNC. For that
                reason, many in SNCC distrusted Johnson on voting rights.">
                President Lyndon Johnson, however, blocked Ms. Hamer’s efforts
                to seat the MFDP as official delegates to the DNC. For that
                reason, many in SNCC distrusted Johnson on voting rights.
              </p>
              <p {...tabbable} ref="leftTextP2" className="alt" aria-label="Freedom Summer and the conflict over the Mississippi Freedom
                Democratic Party exposed the continuing lack of voting equality.">
                Freedom Summer and the conflict over the Mississippi Freedom
                Democratic Party exposed the continuing lack of voting equality.
              </p>
              <p {...tabbable} ref="leftTextP3" aria-label=" LBJ supported a federal voting rights law. But he made a
                War on Poverty his top
                legislative priority.">
                LBJ supported a federal voting rights law. But he made a
                <span className="highlight"> War on Poverty</span> his top
                legislative priority.
              </p>
            </div>
          }
          rightPanel={
            <div ref="rightText" className="chapter-3-scene-7__right">
              <p {...tabbable} aria-label="Martin Luther King, Jr., the leader of the SCLC, wanted LBJ to
                pivot to voting rights">
                Martin Luther King, Jr., the leader of the SCLC, wanted LBJ to{' '}
                <span className="highlight">pivot to voting rights</span>.
              </p>
            </div>
          }
        />
      </FullScreenContainer>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    lbjImageContainer,
    lbjImage,
    mlkImage,
    mlkBg,
    leftText,
    leftTextP1,
    leftTextP2,
    leftTextP3,
    rightText,
    dncBg,
  } = context.refs;

  TweenMax.set(dncBg, {...showElemWithAria});
  TweenMax.set([mlkBg,mlkImage ], {...hideElementWithAria});
  TweenMax.set([leftTextP1,leftTextP2,leftTextP3, rightText], {...hideFromBelow});
  TweenMax.set(lbjImageContainer, {y: '100%', ...showElemWithAria});
  TweenMax.set(lbjImage, {y: '-100%', ...showElemWithAria, ...addTab});

  placeholder(timeline, 1.25)

  timeline.to(dncBg, 1, {opacity: 0.25});
  timeline.to(lbjImageContainer, 1, {y: '0%'}, '-=0.5');
  timeline.to(lbjImage, 1, {y: '0%', ...addTab}, '-=1');
  timeline.to(leftTextP1, 1.5, {...revealFromBelow, ...easeOut}, '-=1');
  timeline.to(leftTextP2, 1.5, {...revealFromBelow, ...easeOut}, '-=1.25');
  timeline.to(leftTextP3, 1.5, {...revealFromBelow, ...easeOut}, '-=1.25');

  timeline.markers.createTimelineMarker();

  timeline.to(leftText, 1, {...hideElementWithAria});
  timeline.to(lbjImage, 1, {opacity: 0.25, ...removeTab}, '-=1');
  timeline.to(dncBg, 1, {...hideElementWithAria}, '-=0.5');
  timeline.to(mlkBg, 1, {...showElemWithAria}, '-=1');
  timeline.to(mlkImage, 1, {...showElemWithAria}, '-=1');
  timeline.to(rightText, 1.5, {...revealFromBelow, ...easeOut}, '-=0.5');
  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    dncBg: file(
      relativePath: { eq: "chapter-3/scene-7/images/ch-4-image-dnc-a@2x.jpg" }
    ) {
      ...largeImage
    }
    lbj: file(
      relativePath: { eq: "chapter-3/scene-7/images/ch-3-image-lbj-pointing@3x.jpg" }
    ) {
      ...largeImage
    }
    mlk: file(
      relativePath: { eq: "chapter-3/scene-7/images/ch-3-image-mlk-writing-foreground@3x.png" }
    ) {
      ...largeImage
    }
    mlkBg: file(
      relativePath: { eq: "chapter-3/scene-7/images/ch-3-image-mlk-writing-speech-bg@2x.jpg" }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(timeline(C3S7, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
